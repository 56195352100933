import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledAlert} from "reactstrap"
import Select from "react-select"
import {useTranslation} from "react-i18next"
import {useForm, usePage} from "@inertiajs/react"
import {toast} from "react-toastify"
import {selectListData} from "@metronic/api/general.js"
import classnames from "classnames"
import {isTwoPrecision, toTwoPrecision} from "@metronic/helpers/utils.jsx"
import _, {capitalize} from "lodash"
import {calculateMaxSum} from "@metronic/api/withdrawal.js"
import {KTIcon} from "@metronic/_metronic/helpers/index"

const WithdrawalCryptoForm = ({isReal, globalActiveTab}) => {
  const {t} = useTranslation()
  const [fees, setFees] = useState([])
  const [assetOptions, setAssetOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [activeTab, setActiveTab] = useState()
  const [warningMessage, setWarningMessage] = useState('')
  const [lockedAssetBalance, setLockedAssetBalance] = useState(0)
  const [lockedAssetUsdBalance, setLockedAssetUsdBalance] = useState(0)
  const [useUnlockedBalance, setUseUnlockedBalance] = useState(false)
  const [assetAmount, setAssetAmount] = useState('')
  const [totalUsdAmount, setTotalUsdAmount] = useState('')
  const [totalWithdraw, setTotalWithdraw] = useState(0.00)
  const [assets, setAssets] = useState([])
  const [dailyWithdraw, setDailyWithdraw] = useState(1)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [blockedTab, setBlockedTab] = useState('')
  const [isClickOnBlockedTab, setIsClickOnBlockedTab] = useState(false)
  const isViteMaxSum = import.meta.env?.VITE_MAX_SUM_ONLY ?? ''
  const {props} = usePage()
  const customerPlan = props.auth.user.profile.plan
  const accountBalance = props.account_balance.all
  const {data, setData, post, reset, errors} = useForm({
    asset_id: '',
    amount: '',
    total_usd: '',
    withdrawal_address: '',
    fee_value: '',
    use_unlocked_balance: ''
  })

  const submitHandler = (e) => {
    e.preventDefault()

    post('/withdrawal/crypto', {
      preserveScroll: true,
      onSuccess(r) {
        if (r.props?.success) {
          toast.success(r.props?.message)
        }
        reset()
      }
    })
  }

  const getFees = (asset_id) => {
    let args = asset_id ? {asset_id: Number(asset_id) ?? null, is_real: isReal ?? false} : null

    selectListData('transaction_crypto_fees', args).then(r => {
      setFees(r)

      Object.entries(r).map(([key, value]) => {
        if (value.active) {
          setActiveTab(key)
        }
      })
    })
  }

  useEffect(() => {
    if (isReal) {
      setData(data => ({...data, use_unlocked_balance: true}))
    }
  }, [globalActiveTab])

  useEffect(() => {
    getFees()
  }, [])

  useEffect(() => {
    const balance = accountBalance.filter(i => i.type !== 'fiat')
    setAssetOptions(balance.filter(i => !!i.is_real === !!isReal).map(i => ({label: i.code, value: i.asset_id})))
  }, [accountBalance])

  // const clearForm = () => {
  //   formik.setFieldValue('total_usd', '')
  //   formik.setFieldValue('amount', '')
  //   formik.setFieldValue('asset_id', '')
  //   setSelectedAsset('')
  //   setAssetAmount('')
  //   setTotalUsdAmount('')
  //   setTotalWithdraw('')
  //   formik.setFieldValue('withdrawal_address', '')
  // }

  // const changeSelectedAssetHandler = (selectOptions) => {
  //   setSelectedAsset(selectOptions)
  //   formik.setFieldValue('asset_id', selectOptions.value)
  //   getFees(selectOptions.value)
  //
  //   if (assetAmount && selectOptions) {
  //     setTotalUsdAmount((Number(assetAmount) * selectOptions.price).toFixed(2))
  //   }
  // }

  const changeAmountHandler = (value) => {
    setAssetAmount(value)
    setData(data => ({...data, amount: value}))

    if (selectedAsset) {
      const newTotal = (Number(value) * accountBalance.find(i => i.asset_id === selectedAsset.value)?.price).toFixed(2)

      setTotalUsdAmount(newTotal)
      setData(data => ({...data, total_usd: newTotal > 0 ? newTotal : ''}))
    }
  }

  const changeTotalUsdAmountHandler = (value) => {
    setTotalUsdAmount(value)
    setData(data => ({...data, total_usd: value}))

    if (activeTab && selectedAsset) {
      const newAmount = (Number(value) / accountBalance.find(i => i.asset_id === selectedAsset.value)?.price).toFixed(8)

      setAssetAmount(newAmount)
      setData(data => ({...data, amount: newAmount > 0 ? newAmount : ''}))
    }
  }

  function toggleTab(tab) {
    if (activeTab !== tab && !fees[tab].blocked) {
      setActiveTab(tab)
    }
  }

  const calcFee = (activeTab) => {
    if (activeTab) {
      setDailyWithdraw(fees[activeTab].limit_title)
      return (fees[activeTab].fee / 100) + 1
    }

    return 1
  }

  useEffect(() => {
    if (!isReal && isViteMaxSum === 'true' && !_.isEmpty(selectedAsset) && activeTab) {
      let data = {
        asset_id: selectedAsset.value,
        fee_value: activeTab
      }

      calculateMaxSumHandler(data)
    }
  }, [activeTab, selectedAsset, isReal])

  const calculateMaxSumHandler = e => {
    let data

    if (!isReal && isViteMaxSum === 'true') {
      data = e
    } else {
      let formEl = e.target.closest('form')
      let formData = Object.fromEntries(new FormData(formEl))

      if (!!isReal) {
        data = {...formData, use_unlocked_balance: true}
      } else {
        data = formData
        data.fee_value = activeTab
      }
    }

    calculateMaxSum(data).then(r => {
      if (r.asset_amount > 0) {
        if (r.success) {
          setData(data => ({...data,
            amount: Number(r.asset_amount).toFixed(8) * 1,
            total_usd: Number(r.total_usd_amount_without_fee).toFixed(2)
          }))
          setAssetAmount(Number(r.asset_amount).toFixed(8) * 1)
          setTotalUsdAmount(r.total_usd_amount_without_fee)
          setTotalWithdraw(r.total_asset_amount)
        } else {
          toast.error(r.message)
        }
      } else {
        toast.warning(r.message)
      }
    })
  }

  const calcAmount = (assetAmount) => {
    return Number(assetAmount * calcFee(activeTab)).toFixed(8) * 1
  }

  useEffect(() => {
    setTotalWithdraw(calcAmount(assetAmount))
  }, [selectedAsset, totalUsdAmount, activeTab, assetAmount])

  useEffect(() => {
    let lockedBalance = accountBalance.filter(i => !!i.is_real === !!isReal)
    let withdrawAsset = lockedBalance?.find(i => Number(i.asset_id) === Number(selectedAsset.value || ''))
    let assets = []
    lockedBalance.forEach(i => {
      if (i.type === 'crypto') {
        assets.push({value: i.asset_id, label: i.code, price: i.price})
      }
    })

    setAssets(assets)
    setLockedAssetBalance(withdrawAsset?.balance || 0)
    setLockedAssetUsdBalance(withdrawAsset?.balance * withdrawAsset?.price || 0)
  }, [accountBalance, selectedAsset, useUnlockedBalance])

  // useEffect(() => {
  //   getFees()
  //
  //   getProfile().then(r => {
  //     setCustomerPlan(r.plan)
  //   })
  // }, [])

  useEffect(() => {
    // formik.setFieldValue('total_usd', totalUsdAmount)
    // formik.setFieldValue('amount', assetAmount)
  }, [totalUsdAmount, assetAmount]);

  useEffect(() => {
    if (assetAmount) {
      changeAmountHandler(assetAmount)
    }
  }, [selectedAsset])

  useEffect(() => {
    setData('fee_value', activeTab)
  }, [fees, activeTab])

  useEffect(() => {

    if (fees && blockedTab) {
      setWarningMessage(() => {
        return (
          <Row className={classnames('', {'d-none': !isClickOnBlockedTab})}>
            <Col xs={12}>
              <div
                className="w-100 bg-light-warning border border-warning rounded-2 d-flex align-items-center ps-3 p-0 mb-5">
                <span className="svg-icon svg-icon-2hx svg-icon-warning me-4 mb-5 mb-sm-0">
                  <KTIcon iconName="information-5" className="fs-2x text-warning"/>
                </span>

                <div className="d-flex flex-column text-warning pe-0 pe-sm-10 fs-5">
                  <span>{t(`${fees[blockedTab]?.blocked_message}`)}</span>
                </div>

                <button
                  type="button"
                  className="m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                  onClick={() => setIsClickOnBlockedTab(false)}
                >
                  <span className="svg-icon svg-icon-1 svg-icon-warning">
                    <i className='bi bi-x fs-2x'></i>
                  </span>
                </button>
              </div>
            </Col>
          </Row>
        )
      })
    }
  }, [blockedTab, fees, isClickOnBlockedTab])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div>
          <Row className="mb-3">
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="text-muted">{t("Available_w")}</span>
                <span className="fs-15">
                <span>
                  {isTwoPrecision(selectedAsset.label, lockedAssetBalance) + ' '}
                  (${toTwoPrecision(lockedAssetUsdBalance)}) {selectedAsset.label}
                </span>
              </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted me-2">{t("Amount")}</span>
                <span className="fs-15">
                {t("Remaining Daily Withdrawal Amount")}:
                <span className="change_limit ps-1">
                  {dailyWithdraw === 'Unlimited' ? t('Unlimited') : `${dailyWithdraw}`}
                </span>
              </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* BEGIN Form fields */}
              <Row>
                <Col>
                  <div className="input-group mb-5 d-flex">
                    <Select
                      className="w-100"
                      placeholder={t("Select currency . . . ")}
                      name="asset_id"
                      onChange={(selected) => {
                        setSelectedAsset(selected)
                        setData('asset_id', selected.value)
                        setIsClickOnBlockedTab(false)
                        getFees(selected.value)
                      }}
                      value={selectedAsset}
                      options={assetOptions}
                    />
                  </div>
                  <div className="input-group mb-5 d-flex">
                    <span className="input-group-text w-25">{t("Amount")}</span>
                    <Input
                      type="text"
                      name="amount"
                      placeholder="0.00"
                      className="form-control"
                      autoComplete="off"
                      disabled={!isReal && String(isViteMaxSum) === 'true'}
                      value={data.amount}
                      onChange={(e) => {
                        changeAmountHandler(e.target.value)
                      }}
                    />
                    <span className="input-group-text justify-content-center">
                      {selectedAsset.label}
                    </span>
                  </div>
                  <div className="input-group mb-5 d-flex">
                    <span className="input-group-text w-25">{t("Total")}</span>
                    <Input
                      type="text"
                      name="total_usd"
                      placeholder="0.00"
                      className="form-control selected-currency-filed"
                      disabled={!isReal && String(isViteMaxSum) === 'true'}
                      value={data.total_usd}
                      onChange={(e) => {
                        changeTotalUsdAmountHandler(e.target.value)
                      }}
                    />
                    <span className="input-group-text justify-content-center">USD</span>
                  </div>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col xs={12}>
                  <div>
                    <a
                      href="#"
                      className="btn btn-link btn-color-primary btn-active-color-primary"
                      onClick={(e) => {
                        if (!isReal && isViteMaxSum === 'true') {
                          return
                        }

                        calculateMaxSumHandler(e)
                      }}
                    >
                      {t("Max sum")}
                    </a>
                  </div>
                </Col>
              </Row>
              {customerPlan === 'network overloaded' &&
                <Row>
                  <Col xs={12}>
                    <div role="alert" className="alert alert-warning">
                      {t("Network is busy. Gas prices are high and estimates are less accurate.")}
                    </div>
                  </Col>
                </Row>
              }

              {warningMessage}

              {/* END Form fields */}

              <Row>
                <Col className="d-flex flex-column flex-sm-row justify-content-between">
                  <div>
                    <h3 className="fw-bold">{t("Fee")}</h3>
                  </div>
                  <div className="border-1">
                    <Nav
                      className="custom-nav nav-justified square border border-primary rounded"
                      role="tablist"
                    >
                      {fees &&
                        Object.keys(fees).map((fee) =>
                          <NavItem key={fee}>
                            <NavLink
                              href="#"
                              id="steparrow-gen-info-tab"
                              className={classnames({
                                active: activeTab === fee,
                                "bg-primary text-inverse-primary rounded": activeTab === fee,
                              })}
                              onClick={() => {
                                toggleTab(fee)
                                setBlockedTab(fee)
                                if (fees[fee]?.blocked) {
                                  setIsClickOnBlockedTab(true)
                                } else {
                                  setIsClickOnBlockedTab(false)
                                }
                              }}
                            >
                              {t(`${capitalize(fee)}`)}
                            </NavLink>
                          </NavItem>)
                      }
                    </Nav>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 mt-1">
                <Col>
                  <TabContent activeTab={activeTab}>
                    {Object.keys(fees).map((fee) =>
                      <TabPane key={fee} id="steparrow-gen-info" tabId={fee}>
                        <div>
                          <Row>
                            <Col className="d-flex justify-content-between">
                            <span className="fs-14 me-2">
                              {t("Withdrawal Fee")}: {fees[fee].fee}% ({toTwoPrecision(totalUsdAmount * (Number(fees[fee].fee) / 100))}$)
                            </span>
                              <span className="fs-14">{t("Estimated time")}: {t(fees[fee].time)}</span>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </Col>
              </Row>
              <Row className="mb-3 border-top">
                <Col className="lh-lg">
                  <div className="pt-1">
                  <span className="fs-6 fw-bold text-gray-600"
                  >
                   {t("Withdrawal Amount")}:
                    <span className="float-end">
                      {isTwoPrecision(selectedAsset.code, assetAmount)} {selectedAsset.label}
                    </span>
                  </span>
                  </div>
                  {isReal &&
                    <div>
                    <span className="fs-6 fw-bold text-gray-600">
                      {t("You receive")}:
                      <span className="float-end">
                        {/*REAL*/}
                        {isTwoPrecision(selectedAsset.label, (assetAmount - (assetAmount *
                          (Number(fees[activeTab]?.fee) / 100))))} {selectedAsset.label}
                      </span>
                    </span>
                    </div>
                  }
                  {!isReal &&
                    <>
                      <div>
                      <span className="fs-6 fw-bold text-gray-600">
                        {t("Paying from Available Balance")}:
                        <span className="float-end">
                          {/*!REAL*/}
                          {isTwoPrecision(selectedAsset.label, (assetAmount * (Number(fees[activeTab]?.fee) / 100)))} {selectedAsset.label}
                        </span>
                      </span>
                      </div>
                      <div>
                      <span className="fs-6 fw-bold">
                        {t("Total Amount")}:
                        <span className="float-end">
                          {isTwoPrecision(selectedAsset.label, totalWithdraw)} {selectedAsset.label}
                        </span>
                      </span>
                      </div>
                    </>
                  }
                </Col>
              </Row>
              <Row className="mb-3 pt-3">
                <Col>
                  <div>
                    <label htmlFor="form-label">{t("Withdraw Address")}</label>
                    <Input
                      type="text"
                      name="withdrawal_address"
                      className="form-control"
                      value={data.withdrawal_address}
                      onChange={(e) => setData('withdrawal_address', e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col className="text-center">
                  <Button
                    type="submit"
                    className="btn btn-sm btn-light-success border border-dashed border-success w-75 fs-16 hover-scale"
                  >
                    WITHDRAW <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/*<input*/}
        {/*  type="hidden"*/}
        {/*  name="fee_value"*/}
        {/*  value={activeTab}*/}
        {/*  onChange={() => {}}*/}
        {/*></input>*/}
      </Form>
    </>
  )
}

export default WithdrawalCryptoForm
